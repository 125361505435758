import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

import VideoPlayer from "@/components/VideoPlayer";

const VideoPlayerRenderer = ({
  isDesktop,
  isMobile,
  isTablet,
  video,
  videoMobile,
}) => {
  const videoAsset = get(video, "[0].fields.videoFile.fields.file", null);
  const videoLoop = get(video, "[0].fields.loop", false);
  const videoAutoPlay = get(video, "[0].fields.autoPlay", false);
  const videoAssetCoverImageSrc = get(
    video,
    "[0].fields.coverImageFile.fields.file.url",
    null
  );

  const videoMobileLoop = get(videoMobile, "[0].fields.loop", false);
  const videoMobileAutoPlay = get(videoMobile, "[0].fields.autoPlay", false);
  const videoMobileAsset = get(
    videoMobile,
    "[0].fields.videoFile.fields.file",
    null
  );
  const videoMobileSrcCoverImage = get(
    videoMobile,
    "[0].fields.coverImageFile.fields.file.url",
    null
  );
  const videoMobileShowControls = get(
    videoMobile,
    "[0].fields.showControls",
    false
  );
  const videoShowControls = get(video, "[0].fields.showControls", false);
  return (
    <>
      {videoAsset && isDesktop && (
        <VideoPlayer
          autoPlay={videoAutoPlay}
          controls={videoShowControls}
          data={{
            videoAsset,
            posterSrc: videoAssetCoverImageSrc,
          }}
          loop={videoLoop}
        />
      )}

      {videoMobileAsset && (isMobile || isTablet) && (
        <VideoPlayer
          autoPlay={videoMobileAutoPlay}
          controls={videoMobileShowControls}
          data={{
            videoAsset: videoMobileAsset,
            posterSrc: videoMobileSrcCoverImage,
          }}
          loop={videoMobileLoop}
        />
      )}
    </>
  );
};

VideoPlayerRenderer.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  video: PropTypes.array.isRequired,
  videoMobile: PropTypes.array.isRequired,
};

export default VideoPlayerRenderer;
